const data = [
  {
    id: `waluta`,
    textPadding: ``,
    title: {
      name: `waluta`,
      search: `waluta`,
    },
    text: `odmiana pieniędzy, jaką posługują się mieszkańcy danego kraju, czyli na przykład polski złoty czy amerykański dolar. Na świecie jest aż 180 różnych walut, a każda z nich ma swój własny symbol.`,
  },
  {
    id: `mennica`,
    textPadding: ``,
    title: {
      name: `mennica`,
      search: `mennica`,
    },
    text: `zakład, w którym produkuje się (wybija) monety należące do konkretnej waluty. Większość państw ma własną mennicę. Mennica Polska znajduje się w Warszawie.`,
  },
  {
    id: `oszczednosci`,
    textPadding: ``,
    title: {
      name: `OSZCZĘDNOŚCI`,
      search: `OSZCZĘDNOŚCI`,
    },
    text: `część budżetu, która pozostaje na naszym koncie bankowym za każdym razem, gdy powstrzymujemy się przed niepotrzebnymi zakupami. Gromadzenie oszczędności pomaga nam spełniać małe (a z czasem również duże) marzenia!`,
  },
  {
    id: `transakcja`,
    textPadding: ``,
    title: {
      name: `TRANSAKCJA`,
      search: `TRANSAKCJA`,
    },
    text: `wyobraź sobie, że rodzice kupują Ci nowy rower. Wybieracie go, słuchacie porad sprzedawcy, a w końcu dochodzi do transakcji. Czyli kupujecie coś, co ktoś sprzedaje. Transakcje, czyli zakupy mogą odbywać się również przez internet.`,
  },
  {
    id: `inwestycja`,
    textPadding: ``,
    title: {
      name: `INWESTYCJA`,
      search: `INWESTYCJA`,
    },
    text: `zakup, który przynosi korzyści, niekoniecznie finansowe. Inwestycją może być przeznaczenie oszczędności z kieszonkowego na:– wędkę, bo dzięki niej złowisz rybę, którą będziesz mógł sprzedać wujkowi;– książkę, z której nauczysz się wielu ciekawych rzeczy.
    `,
  },
  {
    id: `gotowka`,
    textPadding: ``,
    title: {
      name: `GOTÓWKA`,
      search: `GOTÓWKA`,
    },
    text: `pieniądze w formie fizycznej, czyli banknoty i monety. Gotówkę zazwyczaj nosimy przy sobie, w portfelu. `,
  },
  {
    id: `premia`,
    textPadding: ``,
    title: {
      name: `premia`,
      search: `premia`,
    },
    text: `dodatkowe pieniądze, które otrzymujesz za wykonanie jakiegoś zadania. Szczególnie gdy podchodzisz do niego wyjątkowo starannie.`,
  },
  {
    id: `limit`,
    textPadding: ``,
    title: {
      name: `limit`,
      search: `limit`,
    },
    text: `ograniczenie, które sprawia, że możesz przelać na konto innej osoby lub wypłacić z bankomatu określoną sumę pieniędzy i ani grosika więcej. Limity zdarzają się również podczas płatności zbliżeniowych. Tak jest bezpieczniej!`,
  },
  {
    id: `kantor`,
    textPadding: ``,
    title: {
      name: `kantor`,
      search: `kantor`,
    },
    text: `niewielki budynek, w którym wymieniamy walutę jednego kraju na inną, na przykład przed zagraniczną podróżą.`,
  },
  {
    id: `numer-konta-26-cyfr`,
    textPadding: ``,
    title: {
      name: `numer konta <br /> 26 cyfr`,
      search: `numer konta 26 cyfr`,
    },
    text: `26 cyfr, które są przypisane do konkretnego klienta banku i zawierają informacje na temat konta. Numer konta bankowego przydaje się na przykład wtedy, gdy chcemy zrobić komuś przelew.`,
  },
  {
    id: `platnosci-zblizeniowe`,
    textPadding: ``,
    title: {
      name: `PŁATNOŚCI <br /> ZBLIŻENIOWE `,
      search: `PŁATNOŚCI ZBLIŻENIOWE`,
    },
    text: `są wtedy, gdy zbliżasz do terminala płatniczego swoją kartę płatniczą (Kartę Samodzielniaka), aby zapłacić za zakupy w sklepie. Pieniądze z bankomatu również możesz pobrać zbliżeniowo.
    To bardzo szybkie i wygodne rozwiązanie, szczególnie gdy się spieszymy.`,
  },
  {
    id: `bankomat`,
    textPadding: ``,
    title: {
      name: `BANKOMAT`,
      search: `BANKOMAT`,
    },
    text: `budka z ekranem, która służy do wypłacania pieniędzy z konta bankowego, czyli doręczania gotówki. Wystarczy, że włożysz do bankomatu kartę płatniczą
    i wpiszesz swój numer PIN.`,
  },
  {
    id: `numer-pin`,
    textPadding: ``,
    title: {
      name: `NUMER PIN`,
      search: `NUMER PIN`,
    },
    text: `kilkucyfrowe, tajne hasło, które działa jak klucz do Twojej karty płatniczej. Dzięki niemu możesz na przykład wypłacić pieniądze z bankomatu. Zapamiętaj swój numer PIN i nie zdradzaj go nikomu poza mamą i tatą.
    Zawsze dbaj o bezpieczeństwo swoich pieniędzy!`,
  },
  {
    id: `bank`,
    textPadding: `padding-right: 45%;`,
    title: {
      name: `BANK`,
      search: `BANK`,
    },
    text: `miejsce, najczęściej budynek, w którym przechowuje się pieniądze wielu osób.
    Są w nim zupełnie bezpieczne.`,
  },
  {
    id: `bankier`,
    textPadding: `padding-right: 40%;`,
    title: {
      name: `Bankier`,
      search: `Bankier`,
    },
    text: `właściciel lub dyrektor banku, czyli osoba, która dba o to, by ludzie mogli zarządzać swoimi pieniędzmi bez żadnych przeszkód.`,
  },
  {
    id: `aplikacja-bankowa`,
    textPadding: ``,
    title: {
      name: `APLIKACJA <br /> BANKOWA`,
      search: `APLIKACJA BANKOWA`,
    },
    text: `specjalny program, dzięki któremu możemy zarządzać naszym budżetem przez internet – obserwować, kiedy
    i na co wydajemy pieniądze. Aplikacja pozwala nam między innymi na robienie przelewów, również tych na telefon.`,
  },
  {
    id: `karta-platnicza`,
    textPadding: ``,
    title: {
      name: `karta <br /> płatnicza`,
      search: `karta płatnicza`,
    },
    text: `niewielka, lekka karta, którą zawsze nosimy w portfelu. W Twoim przypadku jest to Karta Samodzielniaka! Możesz zapłacić nią w sklepie lub wypłacić dzięki niej pieniądze z bankomatu.`,
  },
  {
    id: `konto-bankowe`,
    textPadding: `padding-right: 25%;`,
    title: {
      name: `KONTO <br /> BANKOWE`,
      search: `KONTO BANKOWE`,
    },
    text: `konto bankowe to najbezpieczniejsze
    (i tylko Twoje!) miejsce na przechowywanie Twoich pieniędzy. Bank opiekuje się zgromadzoną przez Ciebie sumą,
    a Ty zarządzasz nią tak, jak chcesz – kupując lub oszczędzając.`,
  },
  {
    id: `kieszonkowe`,
    textPadding: `padding-right: 32%;`,
    title: {
      name: `KIESZONKOWE`,
      search: `KIESZONKOWE`,
    },
    text: `niewielka suma pieniędzy, którą rodzice wręczają swoim dzieciom każdego miesiąca, aby uczyły się, czym są pieniądze i mogły samodzielnie ich używać.`,
  },
  {
    id: `hologram`,
    textPadding: ``,
    title: {
      name: `HOLOGRAM`,
      search: `HOLOGRAM`,
    },
    text: `prostokątny, połyskliwy
    znak znajdujący się na karcie płatniczej. Każdy hologram jest jedyny w swoim rodzaju. To zabezpieczenie przed tymi, którzy chętnie wykorzystaliby Twoją kaskę do własnych celów.`,
  },
  {
    id: `przelew-na-telefon`,
    textPadding: ``,
    title: {
      name: `przelew <br /> na telefon`,
      search: `przelew na telefon`,
    },
    text: `przekazanie pieniędzy za pomocą aplikacji mobilnej. Zamiast numeru konta bankowego osoby, której chcemy zrobić przelew, podajemy jej numer telefonu komórkowego.`,
  },
  {
    id: `karta-kredytowa`,
    textPadding: ``,
    title: {
      name: `KARTA KREDYTOWA`,
      search: `KARTA KREDYTOWA`,
    },
    text: `karta ta w odróżnieniu od zwykłej karty debetowej umożliwia nam wydawanie większej sumy pieniędzy niż ta, którą sami posiadamy. Korzystanie z karty kredytowej jest więc formą pożyczki od banku.`,
  },
  {
    id: `terminal-platniczy`,
    textPadding: ``,
    title: {
      name: `TERMINAL <br /> PŁATNICZY`,
      search: `TERMINAL PŁATNICZY`,
    },
    text: `urządzenie przypominające duży telefon, które zauważysz w większości sklepów. Pokaż pani sprzedawczyni lub panu sprzedawcy to, co chcesz kupić, a poprosi Cię o zbliżenie do terminala Twojej karty płatniczej.
    I już, zapłacone!`,
  },
  {
    id: `budzet`,
    textPadding: ``,
    title: {
      name: `BUDŻET`,
      search: `BUDŻET`,
    },
    text: `budżet to tyle pieniędzy, ile masz w danej chwili. Jesteś ich właścicielem. Budżet może być mniejszy lub większy, ale najważniejsze jest to, by dobrze się nim opiekować. Nie warto wydawać wszystkiego od razu! Lepiej pomyśleć, na co naprawdę potrzebne są Ci pieniądze
    i jak można je zaoszczędzić.`,
  },
  {
    id: `saldo`,
    textPadding: ``,
    title: {
      name: `SALDO`,
      search: `SALDO`,
    },
    text: `suma pieniędzy, które
    w danym momencie
    znajdują się na koncie
    bankowym, a dokładniej
    suma przychodu (kieszonkowe od rodziców) i rozchodu (kaska, którą wydajesz). Nie mylić z saltem! :)`,
  },
  {
    id: `wyciag-z-konta`,
    textPadding: ``,
    title: {
      name: `WYCIĄG <br /> Z KONTA`,
      search: `WYCIĄG Z KONTA`,
    },
    text: `przygotowany przez bank dokument, który obrazuje sposób, w jaki zarządzaliśmy naszym budżetem w wyznaczonym czasie. Wyciąg możemy otrzymać w formie elektronicznej lub fizycznej, czyli na kartce.`,
  },
  {
    id: `pozyczka`,
    textPadding: ``,
    title: {
      name: `POŻYCZKA`,
      search: `POŻYCZKA`,
    },
    text: `suma pieniędzy, którą otrzymujemy od banku na zrealizowanie wybranego przez nas celu. Suma ta musi zostać zwrócona
    w umówionym terminie.`,
  },
  {
    id: `pieniadz`,
    textPadding: ``,
    title: {
      name: `PIENIĄDZ`,
      search: `PIENIĄDZ`,
    },
    text: `niewielki przedmiot (moneta lub banknot), dzięki któremu wymieniamy się różnymi rzeczami. Przekazujemy go, gdy coś kupujemy, a otrzymujemy na przykład za wykonywaną przez nas pracę.`,
  },
  {
    id: `przelew-internetowy`,
    textPadding: ``,
    title: {
      name: `PRZELEW <br /> INTERNETOWY`,
      search: `PRZELEW INTERNETOWY`,
    },
    text: `sposób na to, by przekazać komuś pieniądze nie z ręki do ręki, a z konta bankowego na konto bankowe. Przelewy robimy przez stronę internetową naszego banku lub jego aplikację mobilną – specjalny program na smartfony i tablety.`,
  },
  {
    id: `wytwornia-papierow-wartosciowych`,
    textPadding: ``,
    title: {
      name: `WYTWÓRNIA <br /> PAPIERÓW <br /> WARTOŚCIOWYCH`,
      search: `WYTWÓRNIA PAPIERÓW WARTOŚCIOWYCH`,
    },
    text: `firma produkująca banknoty. Wytwórnia Papierów Wartościowych należy do państwa polskiego, a jej budynek mieści się w Warszawie.`,
  },
]

export default data
