import React, { useState } from 'react'
import styled from 'styled-components'
import Fuse from 'fuse.js'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Layout from '../components/layout'
import SEO from '../components/seo'
import IntroDictionary from '../components/dictionary/intro-dictionary'
import SearchDictionary from '../components/dictionary/search-dictionary'
import ListDictionary from '../components/dictionary/list-dictionary'

import dataDictionary from '../components/dictionary/dictionary-data'

import pageMobileBG from '../images/dictionary/intro_dictionary_sky_bg_mobile.svg'

const PageWrapper = styled.div`
  @media (max-width: 767px) {
    background-image: url(${pageMobileBG});
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% auto;
  }
`

const IndexPage = (props) => {
  const options = {
    keys: ['title.search'],
    threshold: 0.4,
  }

  const qtyPageItems = 9

  const fuse = new Fuse(dataDictionary, options)

  const [dataList, setDataList] = useState(dataDictionary)
  const [activePaginationPage, setActivePaginationPage] = useState(1)
  const [resetPaginationFunc, setResetPaginationFunc] = useState(null)

  const changeActivePaginationPage = (page) => {
    setActivePaginationPage(page)
  }

  const addResetPaginationFunc = (func) => {
    setResetPaginationFunc(func)
  }

  const changeDataSearch = (word) => {
    const resultSearch = fuse.search(word)

    if (resultSearch.length > 0) {
      const normalizeResult = resultSearch.map((result) => {
        return result.item
      })

      setDataList(normalizeResult)
    } else {
      setDataList(dataDictionary)
    }

    if (activePaginationPage !== 1) {
      resetPaginationFunc()
    }
  }

  const addPagination = (items) => {
    let counter = 0
    let currentPage = 1

    items.map((item) => {
      item.page = currentPage

      if (counter < qtyPageItems) {
        counter = counter + 1
      }

      if (counter === qtyPageItems) {
        counter = 0
        currentPage = currentPage + 1
      }

      return item
    })

    return items
  }

  return (
    <Layout>
      <SEO title="Słowniczek bankosłówek" />

      <PageWrapper>
        <IntroDictionary />
        <SearchDictionary setDataSearch={changeDataSearch} />
        <ListDictionary
          data={addPagination(dataList)}
          activePaginationPage={activePaginationPage}
          changeActivePaginationPage={changeActivePaginationPage}
          setResetPaginationFunc={addResetPaginationFunc}
        />
      </PageWrapper>
    </Layout>
  )
}

export default IndexPage
