import React, { useState } from 'react'
import styled from 'styled-components'
import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  InputGroupAddon,
  Button,
  Input,
} from 'reactstrap'

import dictionarySearchIcon from '../../images/search_icon.svg'

const SearchDictionaryWrapper = styled.div``

const SearchDictionaryCol = styled(Col)`
  padding-left: 30%;
  padding-right: 30%;
  margin-bottom: 40px;
  @media (min-width: 575px) and (max-width: 767px) {
    padding-left: 15%;
    padding-right: 15%;
  }
  @media (max-width: 575px) {
    padding-left: 10%;
    padding-right: 10%;
  }
`

const SearchDictionaryInputGroup = styled(InputGroup)`
  border-bottom: 1px solid #252a2c;
`

const SearchDictionaryInput = styled(Input)`
  height: 46px;
  color: #252a2c;
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  @media (max-width: 767px) {
    background-color: transparent;
  }
  &::placeholder {
    font-size: 34px;
    text-align: center;
    @media (max-width: 991px) {
      height: 38px;
      font-size: 28px;
    }
  }
`

const SearchDictionaryInputGroupAddon = styled(InputGroupAddon)``

const SearchDictionaryButton = styled(Button)`
  width: 38px;
  background-color: transparent !important;
  background-image: url(${dictionarySearchIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
`

const SearchDictionary = (props) => {
  const { setDataSearch } = props
  const [search, setSearch] = useState('')

  const changeSearch = (event) => {
    let word = event.target.value

    setSearch(word)
    setDataSearch(word.trim())
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setDataSearch(search)
  }

  return (
    <SearchDictionaryWrapper name="search">
      <Container>
        <Row>
          <SearchDictionaryCol xs={12}>
            <Form onSubmit={handleSubmit}>
              <SearchDictionaryInputGroup>
                <SearchDictionaryInput
                  value={search}
                  onChange={changeSearch}
                  placeholder="SZUKAJ"
                  id="dictionary-search-input"
                />
                <SearchDictionaryInputGroupAddon addonType="append">
                  <SearchDictionaryButton id="dictionary-search-submit" />
                </SearchDictionaryInputGroupAddon>
              </SearchDictionaryInputGroup>
            </Form>
          </SearchDictionaryCol>
        </Row>
      </Container>
    </SearchDictionaryWrapper>
  )
}

export default SearchDictionary
