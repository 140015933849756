import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import { cloudsAnimationWrapper } from '../../components/elements'

import introDictionaryBG from '../../images/dictionary/intro_dictionary_sky_bg.svg'
import introCloudBG from '../../images/home/intro_home_floating_cloud.svg'

const IntroDictionaryWrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  background-image: url(${introDictionaryBG});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: auto;
  margin-bottom: 20px;
  z-index: 10;
  @media (min-width: 767px) and (max-width: 991px) {
    background-position-y: -10%;
    background-size: auto 90%;
  }
  @media (max-width: 767px) {
    background-image: none;
  }
  @media (max-width: 575px) {
    margin-bottom: 20px;
  }
`

const IntroDictionaryCol = styled(Col)`
  text-align: center;
  padding-top: 9.5%;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 4%;
  @media (min-width: 768px) and (max-width: 991px) {
    padding-left: 6%;
    padding-right: 6%;
  }
  @media (max-width: 767px) {
    padding-top: 20%;
    padding-left: 15px;
    padding-right: 15px;
  }
`

const IntroDictionaryTitle = styled.h1`
  color: #060708;
  font-family: ${(props) => props.theme.fontBNPPSans};
  font-size: 40px;
  font-weight: 400;
  line-height: 1;
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 30px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 26px;
  }
  @media (max-width: 575px) {
    font-size: 24px;
  }
`

const IntroDictionaryDesc = styled.p`
  position: relative;
  color: #060708;
  font-family: ${(props) => props.theme.fontBNPPSans};
  font-size: 20px;
  font-weight: 300;
  line-height: 1.2;
  padding-top: 4.5%;
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 16px;
  }
  @media (max-width: 767px) {
    background-color: ${(props) => props.theme.colorWhite};
    padding: 4.5%;
    margin-top: 50px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 17px;
  }
  @media (max-width: 575px) {
    font-size: 16px;
    margin-top: 30px;
    margin-bottom: 0;
  }
  &:before {
    /* content: ''; */
    position: absolute;
    top: 0;
    right: 95%;
    bottom: 0;
    display: block;
    width: 200px;
    background-image: url(${introCloudBG});
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 70%;
  }
  span {
    @media (max-width: 767px) {
      display: none;
    }
  }
  strong {
    display: inline-block;
    font-weight: 700;
    padding-left: 5%;
    padding-right: 5%;
  }
`

const IntroDictionaryCloudsAnimationWrapper = styled(cloudsAnimationWrapper)``

const IntroDictionary = (props) => {
  return (
    <IntroDictionaryWrapper>
      <IntroDictionaryCloudsAnimationWrapper>
        <div className="layersWrapper">
          <div className="cloudLayer once cloudLayerOnce1" />
          <div className="cloudLayer once cloudLayerOnce2" />
          <div className="cloudLayer once cloudLayerOnce3" />
          <div className="cloudLayer once cloudLayerOnce4" />
          <div className="cloudLayer cloudLayer1" />
          <div className="cloudLayer cloudLayer2" />
          <div className="cloudLayer cloudLayer3" />
          <div className="cloudLayer cloudLayer4" />
          <div className="cloudLayer cloudLayer5" />
          <div className="cloudLayer cloudLayer6" />
          <div className="cloudLayer cloudLayer7" />
          <div className="cloudLayer cloudLayer8" />
        </div>
      </IntroDictionaryCloudsAnimationWrapper>
      <Container>
        <Row>
          <IntroDictionaryCol xs={12}>
            {/* {[...Array(20)].map((_, idx) => <br key={idx} />)} */}
            <IntroDictionaryTitle>
              Język bankowy to nie przelewki, ale samodzielne dzieciaki potrafią
              go rozgryźć.
            </IntroDictionaryTitle>
            <IntroDictionaryDesc>
              <span>
                Bankosłówka to zbiór haseł, które ułatwiają poznanie słów ze
                świata bankowego i finansowego. <br />
              </span>
              <strong>
                Wpisz literkę do okna wyszukiwarki, a zobaczysz bankosłówka na
                tę literkę i dowiesz się, co one znaczą.
              </strong>
            </IntroDictionaryDesc>
          </IntroDictionaryCol>
        </Row>
      </Container>
    </IntroDictionaryWrapper>
  )
}

export default IntroDictionary
