import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Container, Row, Col, Card, CardTitle, CardText } from 'reactstrap'
import Slider from 'react-slick'
import { scroller } from 'react-scroll'

import { Button } from '../../components/elements'

import swipeIcon from '../../images/dictionary/swipe_icon.svg'

const ListDictionaryWrapper = styled.div`
  margin-bottom: 60px;
  @media (max-width: 575px) {
    margin-bottom: 30px;
  }
`

const ListDictionaryCardsWrapper = styled(Row)`
  padding-bottom: 30px;
`

const ListDictionaryCardsWrapperDesktop = styled(ListDictionaryCardsWrapper)`
  @media (max-width: 767px) {
    display: none;
  }
`

const ListDictionaryCardsWrapperMobile = styled(ListDictionaryCardsWrapper)`
  display: none;
  overflow: hidden;
  position: relative;
  @media (max-width: 767px) {
    display: flex;
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 15px;
    bottom: 0;
    display: block;
  }
  &:before {
    right: 0;
    height: 3px;
    background-color: #b8b8b8;
  }
  &:after {
    right: 45px;
    height: 6px;
    background-color: ${(props) => props.theme.colorGreenLight};
  }
`

const ListDictionaryCol = styled(Col)`
  display: ${(props) => (props.page === props.current ? 'block' : 'none')};
  margin-bottom: 30px;
`

const ListDictionaryCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  min-height: 370px;
  ${(props) =>
    props.bg &&
    `
    background-image: url(${require(`../../images/dictionary/cards/${props.bg}.jpg`)});
  `}
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
  padding-top: 40px;
  padding-left: 30px;
  padding-right: 30px;
  ${(props) => props.padding}
  border: none;
  box-shadow: 5px 15px 20px rgba(183, 199, 232, 0.35);
  @media (min-width: 767px) and (max-width: 991px) {
    min-height: 320px;
    font-size: 12px;
    padding-left: 15px;
    padding-right: 15px;
  }
  @media (max-width: 767px) {
    background-size: 100% auto;
    padding-left: 15px;
    padding-right: 15px;
    box-shadow: none;
    cursor: pointer;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    min-height: 380px;
  }
`

const ListDictionaryCardTitle = styled(CardTitle)`
  color: transparent;
  font-family: ${(props) => props.theme.fontAmaticSC};
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 40px;
`

const ListDictionaryCardText = styled(CardText)`
  font-size: 14px;
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 12px;
  }
`

const ListDictionaryPaginationWrapper = styled(Col)`
  display: flex;
  justify-content: space-between;
  ${(props) =>
    props.visible === 'false' &&
    `
    visibility: hidden;
  `}
  @media (max-width: 767px) {
    display: none;
  }
  ul {
    display: inline-block;
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
  }
`

const ListDictionaryPaginationItem = styled.li`
  display: inline-block;
  width: 50px;
  height: 100%;
  font-size: 18px;
  text-align: center;
  padding-top: 8px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 0;
  margin-left: 1px;
  margin-right: 1px;
  border: 3px solid transparent;
  transition: all 0.2s ease;
  ${(props) =>
    props.current === 'true' &&
    `
    border-color: ${props.theme.colorGreenLight};
  `}
  cursor: pointer;
  &:hover,
  &:focus {
    border-color: ${(props) => props.theme.colorGreenLight};
  }
`

const ListDictionaryPaginationButton = styled(Button)`
  height: 50px;
  ${(props) =>
    props.page === props.current &&
    `
      visibility: hidden;
  `}
  ${(props) =>
    props.type === 'prev' &&
    `
    padding-left: 70px;
    padding-right: 30px;
  `}
  &:hover {
    &:after {
      ${(props) =>
        props.type === 'prev' &&
        `
        left: 25px;
        right: auto;
      `}
    }
  }
  &:after {
    ${(props) =>
      props.type === 'prev' &&
      `
      left: 30px;
      right: auto;
      transform: translateY(-50%)  scale(-1, 1);
    `}
  }
`

const ListDictionarySlider = styled(Slider)`
  .slick-list {
    @media (max-width: 767px) {
      padding-left: 0 !important;
    }
  }
`

const ListDictionarySwipeIconWrapper = styled(Row)`
  padding-top: 10px;
  @media (min-width: 768px) {
    display: none;
  }
  @media (min-width: 576px) {
    padding-bottom: 50px;
  }
`

const ListDictionarySwipeIcon = styled.div`
  width: 50px;
  height: 45px;
  background-image: url(${swipeIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-left: auto;
  margin-right: auto;
`

const ListDictionary = (props) => {
  const { data, changeActivePaginationPage, setResetPaginationFunc } = props
  const [currentPage, setCurrentPage] = useState(1)
  const [pages, setPages] = useState(1)

  const changePage = (page, animate = false) => {
    setCurrentPage(page)
    changeActivePaginationPage(page)

    if (animate) {
      scroller.scrollTo('search', {
        offset: -50,
        duration: 500,
        smooth: 'linear',
      })
    }
  }

  const resetPage = () => {
    setCurrentPage(1)
    changeActivePaginationPage(1)
  }

  useEffect(() => {
    setPages(data.length > 0 ? data[data.length - 1].page : 0)
    changePage(currentPage)
  }, [data, currentPage])

  useEffect(() => {
    setResetPaginationFunc(() => resetPage)
  }, [])

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '30px',
  }

  const dictionaryCards = (items, currentPage) => {
    return items.map((item) => {
      return (
        <ListDictionaryCol
          xs={12}
          md={4}
          page={item.page}
          current={currentPage}
          key={item.id}
        >
          <ListDictionaryCard bg={item.id} padding={item.textPadding} body>
            <ListDictionaryCardTitle
              tag="h5"
              dangerouslySetInnerHTML={{ __html: item.title.name }}
            />
            <ListDictionaryCardText
              dangerouslySetInnerHTML={{ __html: item.text }}
            />
          </ListDictionaryCard>
        </ListDictionaryCol>
      )
    })
  }

  return (
    <ListDictionaryWrapper>
      <Container>
        <ListDictionaryCardsWrapperDesktop>
          {data && dictionaryCards(data, currentPage)}
        </ListDictionaryCardsWrapperDesktop>
        <ListDictionaryCardsWrapperMobile>
          <Col xs={12}>
            <ListDictionarySlider {...sliderSettings} className="row">
              {data && dictionaryCards(data, currentPage)}
            </ListDictionarySlider>
          </Col>
        </ListDictionaryCardsWrapperMobile>
        <ListDictionarySwipeIconWrapper>
          <Col xs={12}>
            <ListDictionarySwipeIcon />
          </Col>
        </ListDictionarySwipeIconWrapper>
        <Row>
          <ListDictionaryPaginationWrapper
            visible={(data.length > 0).toString()}
          >
            <ListDictionaryPaginationButton
              page={1}
              current={currentPage}
              onClick={() => changePage(currentPage - 1, true)}
              type="prev"
              id="dictionary-pagination-prev"
            >
              POPRZEDNIE
            </ListDictionaryPaginationButton>
            {pages && (
              <ul>
                {[...Array(pages)].map((_, idx) => {
                  const page = idx + 1
                  return (
                    <ListDictionaryPaginationItem
                      current={page === currentPage ? 'true' : 'false'}
                      key={idx}
                      onClick={() => changePage(page, true)}
                      className="dictionary-pagination-page"
                      id={`dictionary-pagination-page-${idx + 1}`}
                    >
                      {page}
                    </ListDictionaryPaginationItem>
                  )
                })}
              </ul>
            )}
            <ListDictionaryPaginationButton
              page={pages}
              current={currentPage}
              onClick={() => changePage(currentPage + 1, true)}
              position="next"
              id="dictionary-pagination-next"
            >
              następne
            </ListDictionaryPaginationButton>
          </ListDictionaryPaginationWrapper>
        </Row>
      </Container>
    </ListDictionaryWrapper>
  )
}

export default ListDictionary
